import React from 'react';
import styled from '@emotion/styled';
import { mq } from 'style/variables';
import sizes from 'style/sizes';

type ContentWrapperProps = {
  position?: 'left' | 'right';
  noSpPadding?: boolean;
  isTopicsVisual?: boolean;
  isStudioDetail?: boolean;
};

const Container = styled.div<ContentWrapperProps>`
  max-width: 1160px;
  margin-bottom: ${sizes.margin[180]};
  margin-left: ${props => (props.position === 'left' ? 0 : 'auto')};
  margin-right: ${props => (props.position === 'right' ? 0 : 'auto')};
  ${mq.onlypc} {
    margin-top: ${props => (props.isStudioDetail ? -60 : 0)}px;
  }
  ${mq.onlysp} {
    margin-bottom: ${sizes.margin[120]};
    ${props => props.noSpPadding || `padding: 0 ${sizes.margin[32]};`}
  }
  & + &.is-topicsVisual {
    margin-top: -${sizes.margin[64]};
    margin-bottom: -${sizes.margin[64]};
  }

  &:last-of-type {
    margin-bottom: 0;
    ${mq.onlysp} {
      margin-bottom: 0;
    }
  }
`;

export const ContentWrapper: React.FC<ContentWrapperProps> = props => {
  return (
    <Container {...props} className={props.isTopicsVisual ? 'is-topicsVisual' : undefined}>
      {props.children}
    </Container>
  );
};
